
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
import { useStore } from "vuex";
import JMCalendar from "../components/JMCalendar.vue";
import { DateTime } from "luxon";
@Options({
  components: {},
})
export default class Registration extends Vue {
  store = useStore();
  docs: Array<any> = [];
  fromDate:Date = new Date()
  toDate:Date = new Date()
  mounted() {
    this.fetchData();
  }
  fetchData() {
    const dtFromDate = DateTime.fromJSDate(this.fromDate)
    const dtToDate = DateTime.fromJSDate(this.toDate)
    let query = {
      fromDate: dtFromDate.day,
      fromMonth: dtFromDate.month,
      fromYear: dtFromDate.year,
      toDate: dtToDate.day,
      toMonth: dtToDate.month,
      toYear: dtToDate.year,
    };
    axios
      .post(`${address}/transactions/get-daily-service`, query)
      .then((response: AxiosResponse) => {
        console.log(response);
        this.docs = response.data.docs;
      });
  }
}
