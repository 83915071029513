
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {},
})
export default class Purchase extends Vue {
  docs: Array<any> = [];
  filter1: string | null = null;
  filter2: string | null = null;
  filter3: string | null = null;
  store = useStore();
  address = address;
  search: string = "";
  count = 0;
  limit = 50;
  pages = 1;
  page = 1;
  show = 20;
  sortby: any = { createdAt: -1 };
  today = new Date();
  products: any = {};
  mounted() {
    // this.fetchData();
    this.getProduct();
  }
  get filtered() {
    return this.docs.filter((el) => el.onsale);
  }
  getMonth(name: string, branch: string, month: number) {
    try {
      let found = this.docs.find(
        (doc) => doc.month == month && doc.branch == branch && doc.name == name
      );
      if (found) return found;
      return { amount: 0, count: 0 };
    } catch (error) {
      return 0;
    }
    return 0;
  }
  fetchData() {
    this.docs = [];
    let query = {
      search: {
        year: this.filter1 ?? undefined,
      },
      page: this.page,
      limit: this.show,
      sort: { code: -1 },
    };
    console.log(query);
    axios.post(`${address}/reports/summary`, query).then((response) => {
      console.log("response", response);
      this.docs = response.data;
      this.pages = response.data.totalPages;
      // doc.map((a:any) => {
      //   this.docs = a
      // })
    });
  }
  getProduct() {
    axios.get(`${address}/products/list`).then((response: AxiosResponse) => {
      this.products = response.data;
    });
  }
}
