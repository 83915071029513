
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import moment from "moment";
import address from "@/variables";

@Options({
  components: {},
})
export default class History extends Vue {
  transactions: Array<any> = [];
  search: string = "";
  count = 0;
  limit = 50;
  pages = 1;
  page = 1;
  sortby: any = { createdAt: -1 };
  store = useStore();
  today = new Date();
  transactionType = "purchase";
  moment = moment
  mounted() {
    this.count = ref(this.store.state);
    this.fetchData();
  }
  async updateVoid(id:string){
   await axios
      .patch(`${address}/transaction/update/${id}`, { status: "voided" })
      .then((response: AxiosResponse) => {
      });
      this.fetchData();
      this.$router.push(`/receipt/${id}`);
  
  }
  isExpired(expDate:string){
    let diff = moment().diff(moment(expDate),'d')
    if(diff>0) return true
    else return false
  }
  updateSkip(idx: number) {
    this.page = idx;
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateLimit() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateType() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateSort(option: any) {
    if ((this.sortby = option)) {
      let k = Object.keys(option)[0];
      this.sortby[k] = this.sortby[k] * -1;
    }
    this.fetchData();
  }
  onSubmit(ev: Event | any) {
    this.fetchData();
  }
  fetchData() {
    console.log("fetched...")
    let search: any = {
      $and: [
        {
          $or: [
            { telephone: this.search == "" ? undefined : this.search },
            { customerName: this.search == "" ? undefined : { $regex: this.search }},
            { cashierName: this.search == "" ? undefined : { $regex: this.search }},
            
            { branch: this.search == "" ? undefined : { $regex: this.search } },
          ],
        },
        { type: this.transactionType == 'all'?undefined:this.transactionType },
      ],
    };
    let query = {
      search: search,
      sort: this.sortby,
      limit: this.limit,
      page: this.page,
    };
    axios
      .post(`${address}/transactions/paginate`, query)
      .then((response: AxiosResponse) => {
        console.log("pages", response.data)
        this.transactions = response.data.docs;
        this.pages = response.data.totalPages;
      });
  }
}
