
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {},
})
export default class Blog extends Vue {
  docs:Array<any> = []
  limit = 5;
  page =  1
  pages: number = 0;
  total = 0;
  sortby: any = { createdAt: -1 };
  store = useStore();

  fetchData() {
    let query = {
      sort: this.sortby, 
      limit: this.limit,
      page: this.page, 
    };
    axios
      .post(`${address}/blogs/paginate`, query)
      .then((response: AxiosResponse) => {
        console.log("get blog", response.data)
        this.docs = response.data.docs;
        this.pages = response.data.totalPages;
        
    });
  }
  updateSkip(idx: number) {
    this.page = idx+1;
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  mounted() {
    this.fetchData()
  }
}
