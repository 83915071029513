
import { Options, Vue } from "vue-class-component";
import Login from "@/views/Login.vue";
import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "./variables";

@Options({
  components: {
    Navigation,
    Login
  },
})
export default class Home extends Vue {
  store = useStore();
  get whitelist(){
    const okaylist = ['sign-redeem'].filter(l=>this.$route.path.search(l)>-1)
    return okaylist.length > 0
  }
  beforeMount() {
    let APIKEY = "2f66df29a833f9c87023ee0bb728531d"
    axios.interceptors.request.use(function (config) {
      const token = APIKEY;
      config!.headers!.apikey = token;
      config!.headers!.token = token;

      return config;
    });
  }

  // mounted() {
  //   axios
  //     .get(`${address}/employee/5cc7a620ee75f53e438acac4`) //Nene 5cc7a620ee75f53e438acac4
  //     .then((response: AxiosResponse) => {
  //       this.store.commit("setEmployee", response.data);
  //       console.log(response.data)
  //     });
  // }
}
