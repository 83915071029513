
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import Card from "@/components/Card2.vue"; // @ is an alias to /src
import RedeemCart from "@/components/RedeemCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import moment from "moment"

@Options({
  components: {
    Card,
    RedeemCart,
  },
})
export default class Purchase extends Vue {
  store = useStore();
  onlyRemain = false
  mounted() {}
  sortRedeem(items:Array<any>){
    try {
      
      return items.sort((a:any,b:any)=>{
        var duration = moment.duration(a.redeemedAt.diff(b.redeemedAt));
        var hours = duration.asHours();
        return hours
      })
    } catch (error) {
      return items
    }
  }
  get filtered() {
    let f1 = this.store.getters.redeemable2.filter((el: any) => {
      return el.type=="purchase"
    });
    // let f2 = f1.filter((el:any)=>{
    //   console.log(el)
    //   return el.category != "บุฟเฟต์" && el.category != "รายครั้ง" && el.category != "สินค้า"
    // })
    return f1
  }
  isRemain(item:any){
    let redeems = item.redeems.filter((el:any)=>(el.redeemedBy??{}).status!="voided")
    return this.onlyRemain?((item.redeemableQty??0)*(item.cartQty??0))-(redeems.map((r:any)=>r.qty??0).reduce((a:number,b:number)=>a+b,0))>0:true
  }
  checkRemain(item:any){
    let redeems = item.redeems.filter((el:any)=>(el.redeemedBy??{}).status!="voided")
    return ((item.redeemableQty??0)*(item.cartQty??0))-(redeems.map((r:any)=>r.qty??0).reduce((a:number,b:number)=>a+b,0))>0
  }
  getRemain(item:any){
    let redeems = item.redeems.filter((el:any)=>(el.redeemedBy??{}).status!="voided")
    return ((item.redeemableQty??0)*(item.cartQty??0))-(redeems.map((r:any)=>r.qty??0).reduce((a:number,b:number)=>a+b,0))
  }
  addToCart(product: any) {
    if(this.checkRemain(product))
      // alert("ยังไม่เปิดให้แลกใช้จริง ใช้เพื่อทดสอบเท่านั้น")
      this.store.commit("addToRedeemCart", product);
    else
      alert("แพคเกจไม่มีจำนวนคงเหลือแล้ว")
  }
}
