
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import moment from "moment";
import {DateTime} from "luxon"
import address from "@/variables";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class History extends Vue {
  transactions: Array<any> = [];
  transaction:any = {}
  redeemable:Array<any> = []
  search: string = "0982601838";
  count = 0;
  limit = 500;
  pages = 1;
  skip = 0;
  sortby: any = { createdAt: -1 };
  store = useStore();
  today = new Date();
  transactionType = "all";
  moment = moment;
  withVoid:boolean = false;
  current = DateTime.now()
  buffet:any = {}
  employees:Array<any> = []
  mounted() {
    this.count = ref(this.store.state);
    if(this.store.getters.getTelephone!="") this.search = this.store.getters.getTelephone
    if(this.$route.params.telephone != undefined) this.search = this.$route.params.telephone.toString()
    this.fetchData();
    this.getEmployee()
  }
  isExpired(str:string){
    let dt = DateTime.fromISO(str)
    return this.current >= dt
  }
  getBalance(index: number, name: string) {
    let docs = this.transactions;
    let filter = this.transactions.filter((o, i) => {
      return i >= index && o.items.name == name;
    });
    let map = filter.map((el) => {
      let amount = (el.items.cartQty ?? 0) * (el.items.redeemableQty ?? 0);
      let redeem = (el.items.cartQty ?? 0) * -1;
      return el.type == "purchase" ? amount ?? 0 : redeem ?? 0;
    });
    let reduce = map.reduce((a, b) => {
      return (a ?? 0) + (b ?? 0);
    });
    // return { map, reduce };
    return parseFloat(reduce.toFixed(2));
  }
  unwindBy(arr: Array<any>, field: string) {
    return arr.reduce(
      (r, o) => r.concat(o[field].map((v: any) => ({ ...o, [field]: v }))),
      []
    );
  }
  async updateVoid(id: string) {
    await axios
      .patch(`${address}/transaction/update/${id}`, { status: "voided" })
      .then((response: AxiosResponse) => {});
    this.fetchData();
    this.$router.push(`/receipt/${id}`);
  }
  // isExpired(expDate: string) {
  //   let diff = moment().diff(moment(expDate), "d");
  //   if (diff > 0) return true;
  //   else return false;
  // }

  getEmployee() {
    var self = this;
    axios
      .get(`${address}/employees/`)
      .then(function (response) {
        self.employees = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(self.employees);
  }


  fetchReceipt(id:string) {
    console.log("id",id)
    axios
      .get(`${address}/transaction/get/${id}`)
      .then((response: AxiosResponse) => {
        console.log(response.data)
        this.transaction = response.data.result;
        axios
          .get(
            `${address}/transactions-redeem/${this.transaction.telephone}`
          )
          .then((response: AxiosResponse) => {
            this.redeemable = response.data;
          });
      });

  }
  updateSkip(idx: number) {
    this.skip = idx;
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateLimit() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateType() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateSort(option: any) {
    if ((this.sortby = option)) {
      let k = Object.keys(option)[0];
      this.sortby[k] = this.sortby[k] * -1;
    }
    this.fetchData();
  }
  onSubmit(ev: Event | any) {
    this.fetchData();
  }

  saveReceipt(){
    axios
      .patch(`${address}/transaction/update/${this.transaction._id}`, this.transaction)
      .then((response:AxiosResponse) => {
        console.log(response)
        this.fetchData()
        alert("บันทึกการแก้ไขแล้ว")
      })
  }
  fetchData() {
    console.log("fetched...");
    // this.transactions = []
    let search: any = {
      $and: [
        {
          $or: [
            { telephone: this.search == "" ? undefined : this.search },
            // { customerName: this.search == "" ? undefined : { $regex: this.search }, },
            // { cashierName: this.search == "" ? undefined : { $regex: this.search }, },
            // { branch: this.search == "" ? undefined : { $regex: this.search } },
          ],
        },
        this.withVoid?{}:{ status: { $ne: "voided" } },
        { type: this.transactionType == "all" ? undefined : this.transactionType, },
      ],
    };
    let query = {
      search: search, 
      sort: this.sortby, 
      limit: this.limit, 
      page: 1, 
    };
    axios
      .post(`${address}/transactions/paginate`, query)
      .then((response: AxiosResponse) => {
        console.log("get trable", response.data)
        let transactions = response.data.docs;
        transactions = this.unwindBy(transactions, "items");
        this.transactions = _.sortBy(transactions, "items.name");
        // this.pages = Math.ceil(response.data.total / this.limit);

    axios
      .get(`${address}/transactions-buffet/${this.search}`)
      .then((response: AxiosResponse) => {
        let remaining =
          response.data[0].buffetPurchased - response.data[0].buffetRedeemed;
        this.buffet = response.data[0]
        
      });
    });
  }
}
