
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Options({
  components: {},
  props: {
    sCalled: Number,
    debug: Boolean,
  },
})
export default class JMSignature2 extends Vue {
  status = "";
  mousePressed: Boolean = false;
  x = 0;
  y = 0;
  canvas!: CanvasRenderingContext2D;
  isDrawing = false;
  actualDraw = false;

  mounted() {
    var c = this.$refs.myCanvas as HTMLCanvasElement;
    this.canvas = c.getContext("2d") as CanvasRenderingContext2D;
  }
  @Watch("sCalled")
  clearArea() {
    this.canvas.setTransform(1, 0, 0, 1, 0, 0);
    this.canvas.clearRect(
      0,
      0,
      this.canvas.canvas.width,
      this.canvas.canvas.height
    );
  }
  showCoordinates(e: any) {
    this.x = e.offsetX;
    this.y = e.offsetY;
    // this.draw(e)
  }
  drawLine(x1: number, y1: number, x2: number, y2: number) {
    let ctx = this.canvas;
    ctx.beginPath();
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.closePath();
  }
  beginDrawing(e: any) {
    this.status = "mousedown";
    let { x, y } = this.getCoord(e);
    this.x = x;
    this.y = y;
    this.isDrawing = true;
  }
  stopDrawing(e: any) {
    this.status = "mouseup";
    if (this.isDrawing) {
      try {
        let { x, y } = this.getCoord(e);
        this.drawLine(this.x, this.y, x, y);
      } catch (error) {}
      this.x = 0;
      this.y = 0;
      this.isDrawing = false;
      let dataURL = this.canvas.canvas.toDataURL();
      this.$emit("update:modelValue", dataURL);
    }
  }

  draw(e: any) {
    this.status = "mousemove";
    if (this.isDrawing) {
      let { x, y } = this.getCoord(e);
      this.drawLine(this.x, this.y, x, y);
      this.x = x;
      this.y = y;
    }
  }
  getCoord(e: any) {
    var bcr = e.target.getBoundingClientRect();
    var x = e.offsetX ?? e.targetTouches[0].clientX - bcr.x;
    var y = e.offsetY ?? e.targetTouches[0].clientY - bcr.y;
    return {
      x,
      y,
    };
  }
}
