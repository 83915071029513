
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
import { useStore } from "vuex";
import { DateTime } from 'luxon';
@Options({
  components: {},
})
export default class Registration extends Vue {
  store = useStore();
  employees: Array<any> = [];
  doc: any = {
    conditions: [],
    muscleAndSkeleton: [],
    circulatory: [],
    otherConditions: [],
    branch: this.store.getters.employee.branch,
  };
  getEmployee(id: string) {
    try {
      let employee = this.employees.find((em) => em._id === id);
      return employee.firstName + " " + employee.lastName;
    } catch (error) {
      return "-";
    }
  }
  mounted() {
    this.fetchData();
    this.fetchEmployee();
  }
  fetchEmployee() {
    axios
      .get(`${address}/employees/list`)
      .then((response) => {
        this.employees = response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  fetchData() {
    if (this.$route.params.id != undefined) {
      axios
        .get(`${address}/customer/get-opd/${this.$route.params.id}`)
        .then((response: AxiosResponse) => {
          this.doc = response.data.result;
          console.log(this.doc);
        });
    }
  }
  get today() {
    return new Date();
  }

  countdown(str:string){
    try {
      let dt = DateTime.fromISO(str).set({year:new Date().getFullYear()})
      let diffCheck = dt.diff(DateTime.now(),'hours')
      if(diffCheck.hours>0){
        const diff = dt.diff(DateTime.now(), ["months", "days", "hours"])
        return diff.months
      }else{
        const diff = dt.set({year:new Date().getFullYear()+1}).diff(DateTime.now(), ["months", "days", "hours"])
        return diff.months
      }
    } catch (error) {
      return "*"
    }
  }
}
