
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";

@Options({
  components: {},
})
export default class employeeForm extends Vue {
  doc:any = {}
  address = address

  submitForm() {
    if(this.$route.params.id!=undefined){
      axios
      .patch(`${address}/employee/update/${this.$route.params.id}`,this.doc)
      .then((response: AxiosResponse) => {
        console.log(response)
      });
    }
    else {
      axios
      .post(`${address}/employee/create`,this.doc)
      .then((response: AxiosResponse) => {
        console.log(response)
      });
    }
  }
  fetchData() {
    axios
      .get(`${address}/employee/get/${this.$route.params.id}`,)
      .then((response: AxiosResponse) => {
        console.log(response)
        this.doc = response.data.result
      });
  }
  mounted() {
    if(this.$route.params.id!=undefined)this.fetchData()
  }
}
