
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";

@Options({
  props: {
    msg: String
  }
})
export default class Cart extends Vue {
  msg!: string
  store = useStore();
  chkActive(path:string){
    return this.$route.path.search(path) != -1
  }
}
