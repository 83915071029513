export { }

declare global {
  interface Date {
    addDays(days: number, useThis?: boolean): Date;
    addMonths(days: number, useThis?: boolean): Date;
    toThaiShort(): String;
    toThaiTime(): String;
    toThaiLong(): String;
    toThaiMonth(): String;
    toHours(): String;
    firstDayOfMonth(): Date;
    lastDayOfMonth(): Date;
    isToday(): boolean;
    clone(): Date;
    isAnotherMonth(date: Date): boolean;
    isWeekend(): boolean;
    isSameDate(date: Date): boolean;
    getStringDate(): String;
    getDayDiff(end: Date): number
    getYearDiff(end: Date): number
  }
  interface Number {
    intToThaiMonth(): String;
  }
}

Date.prototype.toThaiTime = function (): String {
  let date = this.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
  return date == "Invalid Date" ? "-" : date
}

Date.prototype.getDayDiff = function (end: Date): any {
  let startDate = new Date(this);
  let endDate = new Date(end);
  if (endDate < startDate)
    return 0;

  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1);  // Start just after midnight
  endDate.setHours(23, 59, 59, 999);  // End just before midnight
  var diff = endDate.getTime() - startDate.getTime();  // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);
  return days
}
Date.prototype.getYearDiff = function (end: Date): any {
  let startDate = new Date(this);
  let endDate = new Date(end);
  let yearsDiff =  endDate.getFullYear() - startDate.getFullYear();
  if(isNaN(yearsDiff)) return 0
  return yearsDiff;

}

Date.prototype.addDays = function (days: number): Date {
  if (!days) return this;
  let date = this;
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonths = function (months: number): Date {
  if (!months) return this;
  let date = this;
  date.setMonth(date.getMonth() + +months);
  return date;
};

Date.prototype.toThaiShort = function (): String {
  return this.toLocaleDateString('th-TH', { year: 'numeric', month: 'short', day: 'numeric' })
}
Date.prototype.toHours = function (): String {
  // return this.toLocaleTimeString();
  return `${this.getHours().toString().padStart(2,"0")}:${this.getMinutes().toString().padStart(2,"0")} น.`
}

Date.prototype.toThaiLong = function (): String {
  return this.toLocaleDateString('th-TH', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

Date.prototype.toThaiMonth = function (): String {
  return this.toLocaleDateString('th-TH', { month: 'long' })
}


Date.prototype.firstDayOfMonth = function (): Date {
  var y = this.getFullYear(), m = this.getMonth();
  var firstDay = new Date(y, m, 1);
  return firstDay;
}
Date.prototype.lastDayOfMonth = function (): Date {
  var y = this.getFullYear(), m = this.getMonth();
  var lastDay = new Date(y, m + 1, 0);
  return lastDay;
}

Date.prototype.isToday = function (): boolean {
  let today = new Date();
  return this.isSameDate(today);
};

Date.prototype.clone = function (): Date {
  return new Date(+this);
};

Date.prototype.isAnotherMonth = function (date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.isWeekend = function (): boolean {
  return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function (date: Date): boolean {
  return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.getStringDate = function (): String {
  let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let today = new Date();
  if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay()) {
    return "วันนี้";
  } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() + 1) {
    return "พรุ่งนี้";
  } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() - 1) {
    return "เมื่อวาน";
  } else {
    return this.getDay() + ' เดือน ' + monthNames[this.getMonth()] + ' พ.ศ. ' + this.getFullYear();
    //return this.monthNames[this.getMonth()] + ' ' + this.getDay() + ', ' +  this.getFullYear();
  }
}


Number.prototype.intToThaiMonth = function (): String {
  switch (this) {
    case 1:
      return "มกราคม";
      break;
    case 2:
      return "กุมภาพันธ์";
      break;
    case 3:
      return "มีนาคม";
      break;
    case 4:
      return "เมษายน";
      break;
    case 5:
      return "พฤษภาคม";
      break;
    case 6:
      return "มิถุนายน";
      break;
    case 7:
      return "กรกฎาคม";
      break;
    case 8:
      return "สิงหาคม";
      break;
    case 9:
      return "กันยายน";
      break;
    case 10:
      return "ตุลาคม";
      break;
    case 11:
      return "พฤศจิกายน";
      break;
    case 12:
      return "ธันวาคม";
      break;
    default:
      return this + "*";
      break;
  }
}
