
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
@Options({
  components: {},
})
export default class ProductForm extends Vue {
  doc: any = {};
  address = address
  mounted() {
    this.fetchData();
  }
  deleteInclude(i:number){
    console.log(i)
    this.doc.includes.splice(i,1)
  }
  pushIncludes(){
    if(!Array.isArray(this.doc.includes)) this.doc.includes = []
    this.doc.includes.push({
      name:"",
      category:"บริการจากโปรโมชัน",
      currentPrice:0,
      onsale:true
    })
  }
  fetchData() {
    if (this.$route.params.id != undefined) {
      axios
        .get(`${address}/product/get/${this.$route.params.id}`)
        .then((response: AxiosResponse) => {
          this.doc = response.data.result;
          console.log(this.doc);
        });
    }
  }
  postData() {
    if (this.$route.params.id == undefined) this.create();
    else this.save();
  }
  save() {
    axios
      .patch(`${address}/product/update/${this.$route.params.id}`, this.doc)
      .then((response: AxiosResponse) => {
        console.log(response);
        this.$router.push("/product");
      });
  }
  create() {
    console.log(this.doc);
    axios
      .post(`${address}/product/create`, this.doc)
      .then((response: AxiosResponse) => {
        console.log(response);
        this.$router.push("/product");
      });
  }
}
