
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";

@Options({
  components: {},
})
export default class BlogForm extends Vue {
  doc:any = {}
  address = address

  fetchData() {
    axios
      .get(`${address}/blog/get/${this.$route.params.id}`)
      .then((response: AxiosResponse) => {
        console.log("get blog", response.data)
        this.doc = response.data.result;
    });
  }

  submitForm() {
    if(this.$route.params.id != undefined) {
      axios
        .patch(`${address}/blog/update/${this.$route.params.id}`, this.doc)
        .then((response: AxiosResponse) => {
          console.log("patch blog", response)
        // this.$router.push(`/blog`)
      });
    } else {
    // console.log(this.doc);
    axios
      .post(`${address}/blog/create`, this.doc)
      .then((response: AxiosResponse) => {
        console.log(response);
        // this.$router.push(`/blog`)
      });
    }
  }
  mounted() {
    if(this.$route.params.id != undefined) this.fetchData();
  }
}
