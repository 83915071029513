
import axios, { AxiosResponse } from "axios";
import { Options, Vue } from "vue-class-component";
import address from "@/variables";

@Options({
  components: {},
})
export default class Report extends Vue {
  docs: Array<any> = [];
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    axios
      .post(`${address}/reports/remaining`)
      .then((response: AxiosResponse) => {
        this.docs = response.data.preps;
      });
  }
}
