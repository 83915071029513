
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import RedeemCart from "@/components/RedeemCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {
    Card,
    RedeemCart,
  },
})
export default class Purchase extends Vue {
  store = useStore();
  mounted() {
    if(this.store.getters.getTelephone!=""){

    axios
      .get(`${address}/transactions-redeem/${this.store.getters.getTelephone}`)
      .then((response: AxiosResponse) => {
        // console.log(response.data);
        this.store.commit("setRedeemable", response.data);
      });
    }
  }
  get filtered() {
    return this.store.getters.redeemable.filter((el: any) => {
      // return true //el.remaining > 0
      return el.category != "สินค้า" && el.category != "Buffet Package" && el.category != "บุฟเฟต์";
    });
    // let sorted = filtered.sort((a:any,b:any)=>b.remaining-a.remaining)
    // return sorted
  }
  checkRemain(item: any) {
    return item.remaining > 0;
  }

  addToCart(product: any) {
    if (this.checkRemain(product))
      // alert("ยังไม่เปิดให้แลกใช้จริง ใช้เพื่อทดสอบเท่านั้น")
      this.store.commit("addToRedeemCart", product);
    else alert("แพคเกจไม่มีจำนวนคงเหลือแล้ว");
  }
  // addToCart(product: any) {
  //   this.store.commit("addToRedeemCart", product);
  // }
}
