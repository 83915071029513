
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { ref } from "vue";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
@Options({
  props: {
    msg: String,
  },
})
export default class Cart extends Vue {
  msg!: string;
  receiptId: string = "";
  createdAt: Date = new Date();
  store = useStore();
  employee: any = {};
  employees: Array<any> = [];
  doc: any = {
    payment: [],
  };
  processing:boolean = false

  get cart() {
    return ref(this.store.getters.redeemCartItems);
  }
  get amount() {
    return ref(this.store.getters.redeemCartTotalAmount);
  }
  deleteItems(index: number) {
    this.store.commit("deleteItemsRedeemCart", index);
  }
  confirmPurchase(data: any) {
    this.processing = true
    let self = this;
    let preparedCart = this.store.getters.redeemCartItems;
    preparedCart.forEach(function (el: any, i: number) {
      // el.employeeId = self.store.getters.employee._id;
      // el.employeeFullName = `${self.store.getters.employee.firstName} ${self.store.getters.employee.lastName}`;
      // el.cartQty = el.qty;
      delete el.imageUrl;
      delete el._id;
      delete el.employee;
      delete el.createdAt;
      delete el.updatedOn;
      delete el.order;
      delete el.__v;
    });
    let submitPrepared = {
      receiptId: "MunkeawPOS",
      cashierId: this.store.getters.employee._id,
      cashierName:
        this.store.getters.employee.firstName +
        " " +
        this.store.getters.employee.lastName,
      type: "redeem",
      branch: this.store.getters.employee.branch, //สาขา
      items: preparedCart,
      customerId: this.store.getters.customer._id, //UUID ของลูกค้า
      telephone: this.store.getters.customer.telephone, //เบอร์โทรศัพท์ลูกค้า
      customerName: `${this.store.getters.customer.nickName}-${this.store.getters.customer.firstName} ${this.store.getters.customer.lastName}`,
      totalAmount: 0, //ยอดรวม
      status: "completed", //สถานะของใบเสร็จ > completed,voided
      customerRemarks: this.doc.notes, //บันทึกเพิ่มเติมอื่นๆ ที่แสดงบนใบเสร็จ
      employeeRemarks: this.doc.notesEmployeeOnly, //บันทึกที่จะเห็นเฉพาะพนักงาน
      ...this.doc,
    };
    console.log(submitPrepared);
    axios
      .post(`${address}/transaction/create`, submitPrepared)
      .then(function (response: AxiosResponse) {
        console.log(response.data);
        self.receiptId = response.data.doc._id;
        self.createdAt = response.data.doc.createdAt;
        setTimeout(function () {
          self.$router.push(`/receipt/${self.receiptId}`);
          self.processing = false
        }, 500);
      })
      .catch(function (error: Error) {
        console.log(error);
        alert(error);
      });
  }
  getEmployee() {
    var self = this;
    axios
      .get(`${address}/employees/list`)
      .then(function (response) {
        self.employees = response.data;
        self.employee = self.employees[self.employees.length - 1];
        self.$emit("changeEmployee", self.employee);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(self.employees);
  }
  mounted() {
    this.getEmployee()
    // this.cart = ref(this.store.state);
  }
}
