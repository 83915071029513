
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class SignRedeem extends Vue {
  store = useStore();
  isCustomer = true
  doc: any = {
    conditions: [],
    muscleAndSkeleton: [],
    circulatory: [],
    otherConditions: [],
    branch: this.store.getters.employee.branch,
  };
  mounted() {
    this.fetchData();
  }

  save() {
    axios
      .patch(`${address}/transaction/update/${this.$route.params.id}`, {
        customerSignature: this.doc.customerSignature,
        employeeSignature: this.doc.employeeSignature,
      })
      .then((response: AxiosResponse) => {
        console.log(response);
        alert("บันทึกลายเซ็นแล้ว")
      });
  }
  fetchData() {
    if (this.$route.params.id != undefined) {
      axios
        .get(`${address}/transaction/get/${this.$route.params.id}`)
        .then((response: AxiosResponse) => {
          this.doc = response.data.result;
          console.log(this.doc);
        });
    }
  }
}
