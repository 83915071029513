export { }

declare global {
  interface String {
    capitalize(): String;
    formatComma(): String,
  }
  interface Number {
    formatComma(): String,
    formatFull(): String,
    formatMB(): String,
    formatKB(): String,
    formatPercent(): String,
  }
}
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.formatComma = function () {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


Number.prototype.formatComma = function () {
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.formatFull = function () {
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.formatMB = function () {
  let number = this as number
  return (number/1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.formatKB = function () {
  let number = this as number
  return (number/1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.formatPercent = function () {
  try {

    return this.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
  } catch (error) {

    return "- %";
  }
}