import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Purchase from '../views/Purchase.vue'
import Product from '../views/Product.vue'
import ProductForm from '../views/ProductForm.vue'
import Redeem from '../views/Redeem.vue'
import Redeem2 from '../views/Redeem2.vue'
import Member from '../views/Member.vue'
import OPDDetail from '../views/OPDDetail.vue'
import OPDList from '../views/OPDList.vue'
import SignRedeem from "../views/SignRedeem.vue"

import Registration from '../views/Registration.vue'
import History from '../views/History.vue'
import Table from '../views/Table.vue'
import Receipt from '../views/Receipt.vue'
import FullReceipt from '../views/FullReceipt.vue'
import ReceiptEdit from '../views/ReceiptEdit.vue'
import Booking from '../views/Booking.vue'
import Report from '../views/Report.vue'
import Blog from '../views/Blog.vue'
import BlogForm from '../views/BlogForm.vue'
import EmployeeList from '../views/employee/EmployeeList.vue'
import EmployeeForm from '../views/employee/EmployeeForm.vue'
import MonthlyCourseReport from '../views/MonthlyCourseReport.vue'
import NewReport from "../views/NewReport.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Purchase
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/product/form',
    name: 'Product Form',
    component: ProductForm
  },
  {
    path: '/product/edit/:id',
    name: 'Product Edit',
    component: ProductForm
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase
  },
  {
    path: '/redeem',
    name: 'Redeem',
    component: Redeem
  },
  {
    path: '/test',
    name: 'Redeem2',
    component: Redeem2
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/member',
    name: 'Member',
    component: Member
  },
  {
    path: '/member/:id',
    name: 'Member Detail',
    component: Registration
  },
  {
    path: '/opd/:id',
    name: 'OPD Detail',
    component: OPDDetail
  },
  {
    path: '/opd',
    name: 'OPD List',
    component: OPDList
  },
  {
    path: '/sign-redeem/:id',
    name: 'Sign Redeem',
    component: SignRedeem
  },
  {
    path: '/register',
    name: 'Member Registration',
    component: Registration
  },
  {
    path: '/history',
    name: 'Transaction History',
    component: History
  },
  {
    path: '/table',
    name: 'Transaction Table',
    component: Table
  },
  {
    path: '/table/:telephone',
    name: 'Transaction Table By Telephone',
    component: Table
  },
  {
    path: '/receipt/:id',
    name: 'Receipt',
    component: Receipt
  },
  {
    path: '/fullreceipt/:id',
    name: 'Full Receipt',
    component: FullReceipt
  },  
  {
    path: '/receipt/edit/:id',
    name: 'ReceiptEdit',
    component: ReceiptEdit
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/create',
    name: 'Blog Form',
    component: BlogForm
  },
  {
    path: '/blog/edit/:id',
    name: 'Blog Edit',
    component: BlogForm
  },
  {
    path: '/monthly/report',
    name: 'Monthly Course Report',
    component: MonthlyCourseReport
  },
  {
    path: '/new/report',
    name: 'New Report',
    component: NewReport
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/employee/employeeList',
    name: 'Employee List',
    component: EmployeeList
  },
  {
    path: '/employee/employeeForm',
    name: 'Employee Form',
    component: EmployeeForm
  },
  {
    path: '/employee/employeeEdit/:id',
    name: 'Employee edit',
    component: EmployeeForm
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
