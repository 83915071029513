
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class Registration extends Vue {
  store = useStore();
  doc: any = {
    conditions: [],
    muscleAndSkeleton: [],
    circulatory: [],
    otherConditions: [],
    branch: this.store.getters.employee.branch
  };
  resetDoc() {
    this.doc = {
      conditions: [],
      muscleAndSkeleton: [],
      circulatory: [],
      otherConditions: [],
    };
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    if (this.$route.params.id != undefined) {
      axios
        .get(`${address}/customer/get/${this.$route.params.id}`)
        .then((response: AxiosResponse) => {
        
          this.doc = response.data.result;
          console.log(this.doc);
        });
    }
  }
  save() {
    axios
      .patch(
        `${address}/customer/update/${this.$route.params.id}`,
        this.doc
      )
      .then((response: AxiosResponse) => {
        console.log(response);
        this.$router.push("/member");
      });
  }
  create() {
    console.log(this.doc);
    axios
      .post(`${address}/customer/create`, this.doc)
      .then((response: AxiosResponse) => {
        console.log(response);
        this.$router.push("/member");
      });
  }
}
