
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { ref } from "vue";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
@Options({
  props: {
    msg: String,
  },
  components: {},

})
export default class Cart extends Vue {
  msg!: string;
  // cart:Array<any> = []
  amountTendered: number = 0;
  doc: any = {
    payment: [],
  };
  notesCustomer: string = "";
  notesEmployee: string = "";
  receiptId: string = "";
  createdAt: Date = new Date();

  processing:boolean = false

  store = useStore();
  

  get totalPaid() {
    if (this.doc.payment == undefined) return 0;
    let sum = 0;
    this.doc.payment.forEach((el: any) => {
      sum += el.amount;
    });
    return sum;
  }
  resetItems(){
     this.store.commit("deletePurchaseCart");
  }
  deleteItems(index:number){
     this.store.commit("deleteItemsPurchaseCart",index);
  }

  addPayment(paymentType: string) {
    let tender = parseFloat(String(this.amountTendered));
    if (this.doc.payment == undefined) this.doc.payment = [];
    let found = this.doc.payment.findIndex(
      (el: any) => el.paymentType == paymentType
    );
    if (found == -1) {
      this.doc.payment.push({
        paymentType: paymentType,
        amount: tender,
      });
    } else {
      this.doc.payment[found].amount += tender;
    }
    this.amountTendered = 0;
  }
  get cart() {
    return ref(this.store.getters.purchaseCartItems);
  }
  get amount() {
    return ref(this.store.getters.purchaseCartTotalAmount);
  }
  confirmPurchase() {
    this.processing = true
    let self = this;
    if (this.store.getters.purchaseCartTotalAmount === this.totalPaid) {
      let preparedCart = this.store.getters.purchaseCartItems;
      preparedCart.forEach(function(el: any, i: number) {
        el.employeeId = self.store.getters.employee._id;
        el.employeeFullName = `${self.store.getters.employee.firstName} ${self.store.getters.employee.lastName}`;
        el.packageId = el._id;
        // el.cartQty = el.cartQty;
        delete el.imageUrl;
        delete el._id;
        delete el.employee;
        delete el.updatedOn;
        delete el.order;
        delete el.__v;
      });
      let submitPrepared = {
        receiptId: "MunkeawPOS",
        cashierId: this.store.getters.employee._id,
        cashierName:
          this.store.getters.employee.firstName +
          " " +
          this.store.getters.employee.lastName,
        type: "purchase",
        branch: this.store.getters.employee.branch,
        items: preparedCart,
        customerId: "String", //UUID ของลูกค้า
        telephone: this.store.getters.customer.telephone, //เบอร์โทรศัพท์ลูกค้า
        customerName: `${this.store.getters.customer.nickName}-${this.store.getters.customer.firstName} ${this.store.getters.customer.lastName}`,
        totalAmount: this.totalPaid, //ยอดรวม
        status: "completed", //สถานะของใบเสร็จ > completed,voided
        customerRemarks: this.notesCustomer, //บันทึกเพิ่มเติมอื่นๆ ที่แสดงบนใบเสร็จ
        employeeRemarks: this.notesEmployee, //บันทึกที่จะเห็นเฉพาะพนักงาน
        ...this.doc,
      };
      console.log(submitPrepared);
      axios
        .post(`${address}/transaction/create`, submitPrepared)
        .then(function(response: AxiosResponse) {
          console.log(response.data);
          self.receiptId = response.data.doc._id;
          self.createdAt = new Date(response.data.doc.createdAt);
          setTimeout(function() {
            self.$router.push(`/receipt/${self.receiptId}`);
            self.processing = false
          }, 500);
        })
        .catch(function(error) {
          console.log(error);
          alert(error);
        });
    } else {
      alert("ยอดรับชำระไม่ตรงกับยอดเงินที่ต้องชำระ กรุณาตรวจสอบอีกครั้ง");
    }
  }
  mounted() {
    // this.cart = ref(this.store.state);
  }
}
