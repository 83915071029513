
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {},
})
export default class History extends Vue {
  transaction: any = {};
  redeemable: any = [];
  print() {
    window.print();
  }
  mounted() {
    this.fetchData();
  }
  getStaffName(item:any){
    try {
      return (item.firstName??"") + "  " + (item.lastName??"")
    } catch (error) {
      return "-"
    }
  }

  get filtered() {
    return this.redeemable.filter((el: any) => {
      return el.remaining > 0 && el.category != "บุฟเฟต์";
    });
  }

  postData(){
    axios
      .patch(`${address}/transaction/update/${this.$route.params.id}`, this.transaction)
      .then((response:AxiosResponse) => {
        console.log(response)
        alert("บันทึกการแก้ไขแล้ว")
      })
  }

  fetchData() {
    axios
      .get(`${address}/transaction/${this.$route.params.id}`)
      .then((response: AxiosResponse) => {
        this.transaction = response.data;
        axios
          .get(
            `${address}/transactions-redeem/${this.transaction.telephone}`
          )
          .then((response: AxiosResponse) => {
            this.redeemable = response.data;
          });
      });
  }
}
