
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import moment from "moment";
import address from "@/variables";
import QRCode from "qrcode";

@Options({
  components: {},
})
export default class History extends Vue {
  store = useStore();
  transaction: any = {};
  redeemable: any = [];
  moment = moment;
  get host() {
    let domain = window.location.host //"192.168.1.7:8080" //
    return `https://${domain}/sign-redeem/${this.$route.params.id}`;
  }
  generateQRCode() {
    this.$nextTick(() => {
      setTimeout(() => {
        var canvas = this.$refs.signature as HTMLCanvasElement;
        //${this.host}/sign-redeem/${this.$route.params.id}
        QRCode.toCanvas(canvas, this.host, function(error: any) {
          if (error) console.error({ error });
          console.log("success!");
        });
      }, 500);
    });
  }
  print() {
    window.print();
  }
  mounted() {
    this.generateQRCode();
    this.fetchData();
    this.store.commit("deletePurchaseCart");
    this.store.commit("deleteRedeemCart");
  }
  getStaffName(item: any) {
    try {
      return (item.firstName ?? "") + "  " + (item.lastName ?? "");
    } catch (error) {
      return "-";
    }
  }

  get filtered() {
    return this.redeemable.filter((el: any) => {
      return (
        el.remaining > 0 && el.category != "บุฟเฟต์" && el.category != "สินค้า"
      );
    });
  }
  fetchData() {
    axios
      .get(`${address}/transaction/get/${this.$route.params.id}`)
      .then((response: AxiosResponse) => {
        console.log("response", response.data);
        this.transaction = response.data.result;
        axios
          .post(`${address}/transactions/get-redeem/`, {
            telephone: this.transaction.telephone,
          })
          .then((response: AxiosResponse) => {
            console.log(response.data);
            this.redeemable = response.data.docs;
          });
      });
  }
}
