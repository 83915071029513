
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";
import Chart from "chart.js";

@Options({
  components: {},
})
export default class Report extends Vue {
  purchases: Array<any> = [];
  redeems: Array<any> = [];
  spendings: Array<any> = [];
  loggedIn:boolean = false
  chart1: any;
  chart2: any;
  chart3: any;
  password = ""
  tooltip = {
    callbacks: {
      label: function(context: any) {
        return "label";
      },
    },
  };
  ticks = {
    callback: function(label: number, index: number, labels: Array<number>) {
      return label.formatFull() + "฿";
    },
  };
  checkPassword(){
    if(this.password == "12345678") this.loggedIn = true
    else this.loggedIn = false
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    let purchases = await axios.post(`${address}/report-monthly-purchase`);
    let redeems = await axios.post(`${address}/report-monthly-redeem`);
    let spendings = await axios.post(`${address}/report-top-spender`);
    this.purchases = purchases.data.reverse();
    this.redeems = redeems.data.reverse();
    this.spendings = spendings.data.reverse();
    this.initChart1()
    this.initChart2()
    this.initChart3();
  }
  initChart1() {
    var element = this.$refs.chart1 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart1 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.purchases.map((el) => el.year + "/" + el.month),
        datasets: [
          {
            label: "มูลค่าการซื้อ รายเดือน",
            data: this.purchases.map((el) => el.amount),
            barThickness: 30,
            backgroundColor: "#AA6afc",
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: this.ticks,
              scaleLabel: {
                display: true,
                labelString: "บาท",
              },
            },
          ],
        },
      },
    });
  }

  initChart2() {
    var element = this.$refs.chart2 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart1 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.redeems.map((el) => el.year + "/" + el.month),
        datasets: [
          {
            label: "มูลค่าการแลกใช้ รายเดือน",
            data: this.redeems.map((el) => el.amount),
            barThickness: 30,
            backgroundColor: "#AA6afc",
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: this.ticks,
              scaleLabel: {
                display: true,
                labelString: "บาท",
              },
            },
          ],
        },
      },
    });
  }

  initChart3() {
    var element = this.$refs.chart3 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart1 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.spendings.map((el) =>
          (el.customerName ?? "").split("undefined").join("")
        ),
        datasets: [
          {
            label: "มูลค่าการซื้อ",
            data: this.spendings.map((el) => el.amount),
            barThickness: 30,
            backgroundColor: "#AA6afc",
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        plugins:{
          tooltip:{
            callback:{
              label:()=>{
                return "test"
              }
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: this.ticks,
              scaleLabel: {
                display: true,
                labelString: "บาท",
              },
            },
          ],
        },
      },
    });
  }
}
