
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";
@Options({
  props: {
    msg: String,
  },
})
export default class Cart extends Vue {
  msg!: string;
  telephone: string = "";
  store = useStore();
  mounted(){
    
    this.telephone = this.store.getters.getTelephone
    if(this.telephone!=""){

      if(this.$route.path == "/redeem"){
        this.fetchRedeem()
      } else if (this.$route.path == "/purchase"){
        this.fetchPurchase()
      }
    } 
  }
  checkFormat() {
    if (this.telephone.length == 10) {
      this.store.commit("setTelephone",this.telephone)
      // axios
      //   .get(`${address}/transactions-calculate/${this.telephone}`)
      //   .then((response: AxiosResponse) => {});
      this.onSubmit({});
    }
  }
  onSubmit(ev: Event | any) {
    this.store.commit("deleteRedeemCart")
    axios
      .get(`${address}/customer/telephone/${this.telephone}`)
      .then((response: AxiosResponse) => {
        let customer = response.data.doc;
        console.log(response.data)
        if (this.store.state.branch == "ทุกสาขา"){
          this.store.commit("setCustomer", response.data.doc);
          this.fetchRedeem();
          this.fetchPurchase();
        }
        else if (customer.branch != this.store.state.branch) {
          alert("พบชื่อลูกค้าอยู่ที่สาขาอื่น! กรุณาติดต่อสาขา... "+customer.branch);
        } 
        else {
          this.store.commit("setCustomer", response.data.doc);
          this.fetchRedeem();
          this.fetchPurchase();
        }
      });
  }

  fetchRedeem(){
    axios
      .post(`${address}/transactions/get-redeem`, { telephone: this.telephone })
      .then((response: AxiosResponse) => {
        console.log("transactions", response.data.docs);
        this.store.commit("setRedeemable", response.data.docs);
      });
  }
  fetchPurchase(){
    console.log("getting buffet.")
    axios
      .get(`${address}/transactions-buffet/${this.telephone}`)
      .then((response: AxiosResponse) => {
        console.log("buffet!!", response)
        let remaining = response.data.buffetPurchased - response.data.buffetRedeemed;
        console.log('buffet', response.data, remaining);
        this.store.commit("setBuffet", response.data);
      });
  }
}
