
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {
    Card,
    PurchaseCart,
  },
})
export default class Purchase extends Vue {
  products: Array<any> = [];
  count = 0;
  store = useStore();
  mounted() {
    this.count = ref(this.store.state);
    this.fetchData();
  }
  addToCart(product: any) {
    this.store.commit("addToPurchaseCart", product);
  }
  get filtered() {
    return this.products.filter((el) => el.onsale);
  }
  fetchData() {
    axios
      .post(`${address}/products/paginate`,{
        page: 1,
        limit: 99999,
        sort: {
          currentPrice: -1,
        },
      })
      .then((response: AxiosResponse) => {
        console.log("products", response.data)
        this.products = response.data.docs;
      });
  }
}
