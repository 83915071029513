import { createApp, provide, inject } from 'vue'

import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import http from './http'
import "@/extensions/date.extension.ts"
import "@/extensions/number.extension.ts"
import "@/extensions/string.extension.ts"
// import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';


import Search from '@/components/Search.vue'; // @ is an alias to /src
import JMSign from '@/components/JMSign.vue';
import JMUpload from '@/components/JMUpload.vue';
import JMCalendar from "@/components/JMCalendar.vue";
import JMRichText from '@/components/JMRichText.vue';


let app = createApp(App).use(store).use(router)

app.component('Search', Search)
app.component('JMSign', JMSign)
app.component("JMCalendar", JMCalendar);
app.component('JMUpload', JMUpload)
app.component('JMRichText', JMRichText)

app.mount('#app')



// //@ts-ignore
// app.provide('listData', listData)
// app.config.globalProperties.$listData = listData