
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {
    Card,
    PurchaseCart,
  },
})
export default class Purchase extends Vue {
  products: Array<any> = [];
  count = 0;
  store = useStore();
  address = address
  mounted() {
    this.count = ref(this.store.state);
    this.fetchData();
  }
  deleteItem(id: string) {
    axios
      .delete(`${address}/product/${id}`)
      .then((response: AxiosResponse) => {
        this.fetchData();
      });
  }
  duplicate(item: any) {
    let prep = item
    delete prep._id
    axios
      .post(`${address}/product`, item)
      .then((response: AxiosResponse) => {
        this.fetchData();
      });
  }
  addToCart(product: any) {
    this.store.commit("addToPurchaseCart", product);
  }
  get filtered() {
    return this.products.filter((el) => el.onsale);
  }
  fetchData() {
    axios.get(`${address}/products/list`).then((response: AxiosResponse) => {
      this.products = response.data;
    });
  }
}
