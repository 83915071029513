
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    image: String,
    title: String,
    dt: String,
    subtitle: String,
    price: Number,
  },
})
export default class Card extends Vue {
  image!: string;
  title!: string;
  subtitle!: string;
  price!: number;
}
