
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Quill from "quill";
import { v4 as uuidv4 } from "uuid";
@Options({
  components: {},
  props: {
    modelValue: String,
    uuid: String,
    uid: String,
  },
})
export default class AccountList extends Vue {
  uuid!: string;
  uid!: string;
  modelValue!: string;
  editor: any;
  initilized: boolean = false;
  created(){

    // this.uuid = uuidv4();
  }
  mounted() {
    this.initQuill();
  }
  initQuill() {
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      ["link", "image", "video"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: ["#000000"] }], // dropdown with defaults from theme
      [{ align: [] }],
    ];

    var options = {
      modules: {
        toolbar: toolbarOptions,
      },
      imageCompress: {
        quality: 0.65,
        maxWidth: 550,
        imageType: "image/jpeg", // default
        debug: false, // default
      },
      theme: "snow",
    };
    var container = document.getElementById(`editor-${this.uid}`);
    this.editor = new Quill(container!, options);
    this.editor.on("text-change", () => {
      this.$emit("update:modelValue", this.editor.root.innerHTML);
    });
  }

  // @Watch("modelValue", { deep:true })
  updated() {
    if (!this.initilized) {
        this.editor.root.innerHTML = this.modelValue??"";
        this.initilized = true;
      if (this.modelValue != undefined) {
      }
    }
    //@ts-ignore
    // document.getElementById(`editor-${this.id}`).root!.innerHTML = this.modelValue;
  }
}
