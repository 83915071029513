
import { Options, Vue } from "vue-class-component";
import axios, { AxiosResponse } from "axios";
import address from "@/variables";

@Options({
  components: {},
})
export default class Member extends Vue {
  employee: Array<any> = [];
  search: string = "";
  count = 0;
  pages = 1;
  page = 1;
  total = 0;
  sortby: any = { telephone: -1 };
  today = new Date();
  mounted() {
    this.fetchData();
  }
  get filtered() {
    return this.employee.filter((el) => el.onsale);
  }
  onSubmit(ev: Event | any) {
    this.fetchData();
  }
  fetchData() {
    let query = {
        limit:999,
        page:1,
        sort:{branch:-1}
    };
    console.log(query)
    axios
      .post(`${address}/employees/paginate`, query)
      .then((response: AxiosResponse) => {
        this.employee = response.data.docs;
        console.log(response)
        this.total = response.data.total
        this.pages = response.data.totalPages;
      });
  }
}
