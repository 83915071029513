
import { Options, Vue } from "vue-class-component";
import address from "@/variables";

@Options({
  props: {
    image: String,
    imageUrl: String, 
    title: String,
    subtitle: String,
    price: Number,
  },
})
export default class Card extends Vue {
  image!: string;
  imageUrl!: string;
  title!: string;
  subtitle!: string;
  price!: number;
  address = address
}
