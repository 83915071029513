
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { useStore } from "vuex";
import address from "@/variables";
@Options({
  components: {},
})
export default class Login extends Vue {
  store = useStore();
  doc: any = {};
  employees: Array<any> = [];
  employee: any = {};
  developer = {
    createdAt: "2021-10-07T01:49:46.507Z",
    updatedOn: "2021-10-07T01:49:46.507Z",
    _id: "5cc7a620ee75f53e438acac4",
    employeeId: "JM001",
    employeeType: "นักพัฒนาซอฟท์แวร์",
    altName: "Developer",
    nickName: "Developer",
    firstName: "เจเอ็มแอนด์เจเอ็ม ",
    lastName: "โซลูชั่น",
    gender: "-",
    services: "",
    citizenID: "",
    address: "",
    telephone: "",
    lineId: "",
    email: "production@jmandjm.com",
    terminationDate: null,
    active: true,
  };
  retrieveData() {
    let self = this;
    axios
      .post(`${address}/employees/paginate`,{
        search:{
          $or:[
            { employeeType: "cashier" },
            { employeeType: "developer" }
          ]
        },
        limit:999,
        page:1,
        sort:{branch:-1}
      })
      .then(function(response) {
        console.log(response.data);
        self.employees = response.data.docs;
        self.employee = self.employees[self.employees.length - 1];
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  login() {
    // console.log("login", this.store.getters.loginStatus);
    console.log(this.doc)
    if (this.doc.staff.altName == "Developer" && this.doc.password == "190304"){

      this.store.commit("setEmployee", this.doc.staff);
      this.store.commit("logUserIn", this.doc.staff);
    }
    else if (this.doc.staff.altName != "Developer" && this.doc.password == "0000") {
      this.store.commit("setEmployee", this.doc.staff);
      this.store.commit("logUserIn", this.doc.staff);
    } 
    else {
      alert("รหัสผ่านไม่ถูกต้อง");
    }
  }
  mounted() {
    this.retrieveData();
  }
}
