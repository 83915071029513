
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {},
})
export default class History extends Vue {
  bookings: Array<any> = [];
  search: string = "";
  count = 0;
  limit = 9999;
  pages: number = 0;
  page = 1
  total = 0
  sortby: any = { bookedDate: -1 };
  store = useStore();
  today = new Date();
  transactionType = "purchase";
  mounted() {
    this.count = ref(this.store.state);
    this.fetchData();
  }
  updateSkip(idx: number) {
    this.page = idx+1;
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  // updateLimit() {
  //   this.$nextTick(() => {
  //     this.fetchData();
  //   });
  // }
  // updateType() {
  //   this.$nextTick(() => {
  //     this.fetchData();
  //   });
  // }
  // updateSort(option: any) {
  //   if ((this.sortby = option)) {
  //     let k = Object.keys(option)[0];
  //     this.sortby[k] = this.sortby[k] * -1;
  //   }
  //   this.fetchData();
  // }
  // onSubmit(ev: Event | any) {
  //   this.fetchData();
  // }
  fetchData() {
    let query = {
      search:{
        telephone: this.search == "" ? undefined : this.search,
        branch:this.store.state.branch=="ทุกสาขา"?undefined:this.store.state.branch,
      },
      page: this.page,
      limit: this.limit,
      skip: 0,
      sort: this.sortby
    };
    console.log(query)
    axios
      .post(`${address}/bookings/paginate`, query)
      .then((response) => {
        this.bookings = response.data.docs
        this.pages = response.data.totalPages;
      });
  }
  // fetchData() {
  //   let search: any = {
  //     // $and: [
  //     //   {
  //     //     $or: [
  //     //       { telephone: this.search == "" ? undefined : this.search },
  //     //       {
  //     //         customerName:
  //     //           this.search == "" ? undefined : { $regex: this.search },
  //     //       },
  //     //       {
  //     //         cashierName:
  //     //           this.search == "" ? undefined : { $regex: this.search },
  //     //       },

  //     //       // { branch: this.search == "" ? undefined : { $regex: this.search } },
  //     //     ],
  //     //   },
  //     // ],
  //   };
  //   let query = {
  //     search: search,
  //     sort: this.sortby,
  //     limit: this.limit,
  //     skip: this.skip,
  //   };
  //   axios
  //     .post(`${address}/bookings/paginate`, query)
  //     .then((response: AxiosResponse) => {
  //       this.bookings = response.data.docs;
  //       this.bookings = this.bookings.map((el) => {
  //         return {
  //           customer2: JSON.parse(el.customer),
  //           ...el,
  //         };
  //       });
  //       console.log(this.bookings);
  //       this.pages = Math.ceil(response.data.total / this.limit);
  //     });
  // }
}
