
import { Options, Vue } from "vue-class-component";
import { ref, Teleport } from "vue";
import Card from "@/components/Card.vue"; // @ is an alias to /src
import PurchaseCart from "@/components/PurchaseCart.vue"; // @ is an alias to /src
import axios, { AxiosResponse } from "axios";
import { useStore } from "vuex";
import address from "@/variables";

@Options({
  components: {},
})
export default class Member extends Vue {
  customers: Array<any> = [];
  search: string = "";
  count = 0;
  limit = 50;
  pages = 1;
  page = 1;
  total = 0;
  sortby: any = { telephone: -1 };
  store = useStore();
  today = new Date();
  mounted() {
    this.count = ref(this.store.state);
    this.fetchData();
  }
  get filtered() {
    return this.customers.filter((el) => el.onsale);
  }
  updateSkip(idx: number) {
    this.page = idx * this.limit;
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateLimit() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  updateSort(option: any) {
    if ((this.sortby = option)) {
      let k = Object.keys(option)[0];
      this.sortby[k] = this.sortby[k] * -1;
    }
    this.fetchData();
  }
  sum(arr:any){
    if(arr.length>0){
      return arr.reduce((a:any,b:any)=>a+b)
    }
    return 0
  }
  onSubmit(ev: Event | any) {
    this.fetchData();
  }
  fetchData() {
    let search: any = {
      $and:[
        {
        branch:this.store.state.branch=="ทุกสาขา"?undefined:this.store.state.branch,
        },
      {$or: [
        { telephone: this.search == "" ? undefined : this.search },
        { firstName: this.search == "" ? undefined : { $regex: this.search } },
        { lastName: this.search == "" ? undefined : { $regex: this.search } },
        { nickName: this.search == "" ? undefined : { $regex: this.search } },
      ],}
      ]
    };
    if (this.search.search(" ") != -1) {
      search = {
        firstName: this.search.split(" ")[0],
        lastName: this.search.split(" ")[1],
      };
    }
    if (this.search.search("  ") != -1) {
      search = {
        firstName: this.search.split(" ")[0],
        lastName: this.search.split(" ")[2],
      };
    }
    if (this.search.search("   ") != -1) {
      search = {
        firstName: this.search.split(" ")[0],
        lastName: this.search.split(" ")[3],
      };
    }
    let query = {
      search: search,
      sort: this.sortby,
      limit: this.limit,
      page: this.page,
    };
    console.log(query)
    axios
      .post(`${address}/customers/paginate`, query)
      .then((response: AxiosResponse) => {
        this.customers = response.data.docs;
              // console.log(response.data)
        this.total = response.data.total
        this.pages = response.data.totalPages;
        // this.customers.forEach((el) => {
        //   axios
        //     .get(
        //       `${address}/transactions-redeem/${el.telephone}`
        //     )
        //     .then((response: AxiosResponse) => {
        //       let data = response.data;
        //       el.order = data.map((order:any) => {
        //         return {
        //           name: order.name,
        //           amount:order.currentPrice,
        //           qty:order.cartQty.reduce((a:any,b:any)=>(a==null?0:a)+(b==null?0:b))
        //         };
        //       });
        //       this.$forceUpdate();
        //     });
        // });
      });
  }
}
