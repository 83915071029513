import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state() {
    return {
      count: 1,
      purchaseCart: [],
      branch: "",
      redeemCart: [],
      customer: {},
      employee: {},
      redeemable: [],
      redeemable2: [],
      buffetable: {},
      loggedIn: false,
      loggedInAs: {},
      telephone: ""
    }
  },
  mutations: {
    logUserIn(state: any, staff: any) {
      state.loggedIn = true
      state.loggedInAs = staff
      state.employee = staff
      state.branch = staff.branch
    },
    logUserOut(state: any) {
      state.loggedIn = false
      state.loggedInAs = {}
    },
    setEmployee(state: any, employee: any) {
      state.employee = employee
    },
    setCustomer(state: any, customer: any) {
      state.customer = customer
    },
    setRedeemable(state: any, redeemable: any) {
      state.redeemable = redeemable
    },
    setRedeemable2(state: any, redeemable: any) {
      state.redeemable2 = redeemable
    },
    setBuffet(state: any, buffetable: any) {
      state.buffetable = buffetable
    },
    increment(state: any) {
      // mutate state
      state.count++
    },
    addToPurchaseCart(state: any, product: any) {
      let found = state.purchaseCart.findIndex((el: any) => {
        return el._id == product._id
      })
      if (found == -1) {
        state.purchaseCart.push({ ...product, cartQty: 1 })
      }
      else {
        state.purchaseCart[found].cartQty++
      }
    },
    deletePurchaseCart(state: any, product: any) {
      state.purchaseCart = []
    },
    deleteRedeemCart(state: any, product: any) {
      state.redeemCart = []
    },

    deleteItemsPurchaseCart(state: any, index: number) {
      state.purchaseCart.splice(index, 1)
    },

    addToRedeemCartV2(state: any, product: any) {
      let match = state.redeemable2.findIndex((el: any) => {
        return el._id == product._id
      })
      state.redeemable2[match].remaining--

      let found = state.redeemCart.findIndex((el: any) => {
        return el._id == product._id
      })
      if (found == -1) {
        state.redeemCart.push({ ...product, cartQty: 1 })
      }
      else {
        state.redeemCart[found].cartQty++
      }
    },

    addToRedeemCart(state: any, product: any) {
      let match = state.redeemable.findIndex((el: any) => {
        return el._id == product._id
      })
      state.redeemable[match].remaining--

      let found = state.redeemCart.findIndex((el: any) => {
        return el._id == product._id
      })
      if (found == -1) {
        state.redeemCart.push({ ...product, cartQty: 1 })
      }
      else {
        state.redeemCart[found].cartQty++
      }
    },
    deleteItemsRedeemCart(state: any, index: number) {
      state.redeemCart.splice(index, 1)
    },
    setTelephone(state: any, telephone: string) {
      state.telephone = telephone
    }
  },
  getters: {
    getTelephone: state => {
      return state.telephone
    },
    loginStatus: state => {
      return state.loggedIn
    },
    customer: state => {
      return state.customer
    },
    employee: state => {
      return state.employee
    },
    customerName: state => {
      if (state.customer.firstName != undefined)
        return `${state.customer.nickName}-${state.customer.firstName} ${state.customer.lastName}`
      else return "ไม่พบลูกค้า"
    },
    employeeName: state => {
      if (state.employee.firstName != undefined)
        return `${state.employee.nickName}-${state.employee.firstName} ${state.employee.lastName}`
      else return "ไม่พบชื่อพนักงาน"
    },
    redeemable: state => {
      return state.redeemable
    },
    redeemable2: state => {
      return state.redeemable2
    },
    buffetable: state => {
      return state.buffetable
    },
    purchaseCartItems: state => {
      return state.purchaseCart
    },
    purchaseCount: state => {
      return state.purchaseCart.length
    },
    purchaseCartTotalAmount: state => {
      let sum = 0
      state.purchaseCart.forEach((el: any) => {
        sum += el.cartQty * el.currentPrice
      });
      return sum
    },
    redeemCartItems: state => {
      return state.redeemCart
    },
    redeemCount: state => {
      return state.redeemCart.length
    },
    redeemCartTotalAmount: state => {
      let sum = 0
      state.redeemCart.forEach((el: any) => {
        sum += el.cartQty * el.currentPrice
      });
      return sum
    }
  },
  plugins: [createPersistedState({
    paths: ['loggedIn', 'employee', 'loggedInAs', 'branch']
  })],
})


// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
